import React from "react";
//import Router from "./jsx/index";
import Home1 from "./jsx/pages/home1";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/plugins.css";
import "./css/style.css";
import "./css/templete.css";

function App() {
  return (
    <div className="App">
      <Home1 />
    </div>
  );
}

export default App;
